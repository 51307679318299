<template>
  <div>
  <v-list dark class="ap-sidebar-applist">
    <v-list-group
      ref="topLevelItems"
      class="ap-sidebar-group"
      v-for="(item, i) in visibleItems"
      :key="i"
      :prepend-icon="item.icon"
      :value="item.visible"
      @click="onClick(item, false)"
    >
      <v-list-tile slot="activator">
        <v-list-tile-title>{{ item.title }}</v-list-tile-title>
      </v-list-tile>
      <div v-if="item.children">
        <v-list-tile class="sub-list"
          v-for="(subMenu) in item.children"
          :key="subMenu.icon"
          @click="onClick(subMenu, true)"
          :class="subMenu.isActive?'v-list__group__header--active':''"
        >
          <v-list-tile-action>
            <v-icon>{{ subMenu.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-title>{{ subMenu.title }}</v-list-tile-title>
        </v-list-tile>
      </div>
    </v-list-group>
  </v-list>
  <BaseConfirmModal
    :value="showUnsaved"
    :title="unsavedContentTitle"
    :text="unsavedContentText"
    declineText="Cancel"
    @clicked="unsavedModalConfirm"
  />
  </div>
</template>

<style scoped>

.sub-list {
  padding-left: 30px;
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheLeftSidebarAppList',
  props: {
    visible: Boolean,
  },
  data: () => ({
    activeChild: null,
    items: [
      {
        icon: 'group',
        title: 'Users',
        routeTo: 'UserEdit',
        method: null,
        superOnly: false,
        taCanView: true,
        tmCanView: true,
        visible: true,
        children: [
          {
            icon: 'send',
            title: 'Send Quick Alert',
            method: 1,
            isActive: false,
            superOnly: false,
            taCanView: true,
            tmCanView: true,
          },
        ],
      },
      {
        icon: 'domain',
        title: 'Tenants',
        routeTo: 'TenantEdit',
        method: null,
        superOnly: false,
        taCanView: true,
        tmCanView: true,
        visible: false,
        children: [
          {
            icon: 'fingerprint',
            title: 'Tenant Biometrics',
            routeTo: 'TenantBiometricEdit',
            method: 1,
            isActive: false,
            superOnly: false,
            taCanView: false,
            tmCanView: true,
          },
          {
            icon: 'email',
            title: 'Alert Templates',
            routeTo: 'TenantTemplateEdit',
            method: 1,
            isActive: false,
            superOnly: false,
            taCanView: false,
            tmCanView: true,
          },
        ],
      },
      {
        icon: 'assessment',
        title: 'Reports',
        routeTo: 'WorkspaceReports',
        method: null,
        superOnly: false,
        taCanView: true,
        tmCanView: true,
        visible: false,
      },
      {
        icon: 'settings',
        title: 'Server Configuration',
        routeTo: null,
        method: 2,
        superOnly: false,
        taCanView: false,
        tmCanView: true,
        visible: false,
      },
      {
        icon: 'trending_up',
        title: 'Performance',
        routeTo: 'WorkspacePerformance',
        method: null,
        superOnly: true,
        visible: false,
      },
    ],
    showUnsaved: false,
    unsavedContentTitle: 'Unsaved Changes',
    unsavedContentText: 'You have made changes to this page that are not saved. Continue and discard changes?',
    tabItem: {},
    tabIsChild: false,
  }),
  components: {
    BaseConfirmModal: () => import('@/components/base/BaseConfirmModal'),
  },
  computed: {
    ...mapGetters('app', {
      myRole: 'getTopRole',
      stickyTenant: 'getStickyTenant',
      getUnsaved: 'getUnsaved',
    }),
    ...mapGetters('tabs', ['getWorkspace']),
    visibleItems() {
      return this.items.filter(item => this.isVisible(item));
    },
  },
  methods: {
    ...mapActions('alertMessageDialog', ['toggleSendMessageModal']),
    ...mapActions('tabs', {
      setCurrentWorkspace: 'setWorkspace',
    }),
    onClick(item, isChild) {
      // Set component values for use in unsavedModalConfirm().
      this.tabItem = item;
      this.tabIsChild = isChild;
      // First, we need to check to see if there is unsaved data
      // and whether or not the user wants to continue.
      if (this.getUnsaved) {
        this.showUnsaved = true;
      } else {
        this.changeTab(item, isChild);
      }
    },
    updateVisibility(title) {
      for (let i = 0; i < this.items.length; i += 1) {
        if (this.items[i].title === title) {
          this.items[i].visible = true;
        } else {
          this.items[i].visible = false;
        }
        // From here we can filter the child items based on the
        // user's role.
        let visibleChildren = [];
        if ('children' in this.items[i]) {
          visibleChildren = this.items[i].children.filter(item => this.isVisible(item));
        }
        this.items[i].children = visibleChildren;
      }
    },
    isVisible(item) {
      let ret = true;
      if (item.superOnly && this.myRole.roleCode !== 'ROLE_SUPER_ADMIN') {
        ret = false;
      }
      if (!item.taCanView && this.myRole.roleCode === 'ROLE_TENANT_ADMIN') {
        ret = false;
      }
      if (!item.tmCanView && this.myRole.roleCode === 'ROLE_TENANT_MAINT') {
        ret = false;
      }
      return ret;
    },
    customServerRoute() {
      if (this.myRole.roleCode === 'ROLE_SUPER_ADMIN') {
        this.$router.push({ name: 'ServerAppsEdit' });
        return;
      }
      this.$router.push({ name: 'ServerUsersEdit' });
    },
    unsavedModalConfirm(confirm) {
      if (confirm) {
        this.changeTab(this.tabItem, this.tabIsChild);
        this.showUnsaved = false;
      } else {
        this.showUnsaved = false;
      }
    },
    changeTab(item, isChild) {
      if (!isChild) {
        this.setCurrentWorkspace(item.title);
        if (this.activeChild) {
          this.activeChild.isActive = false;
        }
      } else {
        // This is a child item, so we know that we need to remove the
        // v-list__group__header--active class from parent items, so we set
        // headerClasses['v-list__group__header--active'] = false;
        const vm = this;
        this.$refs.topLevelItems.forEach((menuItem, index) => {
          if (menuItem.headerClasses['v-list__group__header--active'] === true) {
            vm.$refs.topLevelItems[index].headerClasses['v-list__group__header--active'] = false;
          }
        });
        if (this.activeChild && this.activeChild !== item) {
          this.activeChild.isActive = false;
        }
        this.activeChild = item;
        this.activeChild.isActive = true;
      }

      if (item.routeTo) {
        this.$router.push({ name: item.routeTo });
      } else if (item.method) {
        const customMethods = {
          1: () => this.toggleSendMessageModal(true),
          2: () => this.customServerRoute(),
        };
        (customMethods[item.method])();
      }
    },
  },
  watch: {
    getWorkspace(wokspace) {
      this.updateVisibility(wokspace);
    },
  },
  mounted() {
    this.updateVisibility(this.getWorkspace);
  },
};
</script>
